import type { ErrorResponse } from "@remix-run/react";
import type { PropsWithChildren } from "react";
import {
  isRouteErrorResponse,
  useNavigate,
  useParams,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import Button from "~/components/Button";
import { getErrorMessage } from "~/utils/errors";

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GlobalErrorBoundary({
  statusHandlers,
  defaultStatusHandler = DefaultStatusHandlerComponent,
  unexpectedErrorHandler = DefaultUnexpectedErrorHandler,
}: {
  statusHandlers?: Record<number, StatusHandler>;
  defaultStatusHandler?: StatusHandler;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  const params = useParams();

  captureRemixErrorBoundaryError(error);

  if (typeof document !== "undefined") {
    console.error(error);
  }

  return (
    <>
      <div>
        {isRouteErrorResponse(error)
          ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
              error,
              params,
            })
          : unexpectedErrorHandler(error)}
      </div>
    </>
  );
}

function DefaultStatusHandlerComponent({ error }: { error: ErrorResponse }) {
  return (
    <ErrorWrapper>
      <p>{error.data}</p>
      <BackButtons />
    </ErrorWrapper>
  );
}

function DefaultUnexpectedErrorHandler(error: unknown) {
  return (
    <ErrorWrapper>
      <p>{getErrorMessage(error)}</p>
      <BackButtons />
      {error instanceof Error && error.stack && error.stack.length > 0 ? (
        <pre className="mt-8 w-full overflow-auto rounded-lg border border-gray-light p-4 text-start text-sm text-gray-darkest shadow-md">
          {error.stack}
        </pre>
      ) : null}
    </ErrorWrapper>
  );
}

function ErrorWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="m-6 text-center">
      <div className="text-xl font-bold text-red-error">
        Une erreur est survenue
      </div>
      <div className="mt-6">{children}</div>
    </div>
  );
}

function BackButtons() {
  const navigate = useNavigate();
  return (
    <div className="mt-6 flex items-center justify-center gap-4">
      <Button label="Retour" onClick={() => navigate(-1)} />
      <Button label="Recharger la page" onClick={() => navigate(0)} />
    </div>
  );
}

export function NotFoundPage({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <div className="text-center">
      <h1 className="text-xl font-bold text-red-error">
        Cette page n&apos;existe pas.
      </h1>
      <p className="mt-6">{children}</p>
      <p className="text-4xl">404</p>
      <div className="mt-8 flex items-center justify-center gap-4">
        <Button label="Page précédente" onClick={() => navigate(-1)} />
        <Button label="Retour à l'accueil" as="a" href="/" />
      </div>
    </div>
  );
}
