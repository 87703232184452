/** FormFieldErrors is a record of field names and their error messages */
export type FormFieldErrors = Record<string, string>;

/** Options to create an AppError */
export type AppErrorOptions = {
  status?: number | null;
  fieldErrors?: FormFieldErrors | null;
  data?: unknown | null;
};

/** Custom AppError class to properly handle errors in the app */
export class AppError extends Error {
  message: string;
  status: number;
  fieldErrors: FormFieldErrors | null;
  data: unknown;

  constructor(
    message: string | null,
    options: AppErrorOptions = { status: 500, fieldErrors: null, data: null }
  ) {
    super(message ?? "Une erreur est survenue.");
    this.name = "AppError";
    this.message = message ?? "Une erreur est survenue.";
    this.status = options.status ?? 500;
    this.fieldErrors = options.fieldErrors ?? null;
    this.data = options.data ?? null;
  }

  getAllErrors() {
    return {
      globalError: this.message,
      fieldErrors: this.fieldErrors,
      errorData: this.data,
    };
  }
}

// Specific error classes
export class BadRequestError extends createSpecificError({
  name: "BadRequestError",
  defaultMessage: "La requête est invalide.",
  defaultStatus: 400,
}) {}

export class MissingTokenError extends createSpecificError({
  name: "MissingTokenError",
  defaultMessage:
    "Vous devez être connecté pour effectuer cette action ou acceder a cette ressource.",
  defaultStatus: 401,
}) {}

export class InvalidTokenError extends createSpecificError({
  name: "InvalidTokenError",
  defaultMessage:
    "Votre session est invalide. Veuillez vous reconnecter pour continuer.",
  defaultStatus: 401,
}) {}

export class ExpiredTokenError extends createSpecificError({
  name: "ExpiredTokenError",
  defaultMessage:
    "Votre session a expiré. Veuillez vous reconnecter pour continuer.",
  defaultStatus: 401,
}) {}

export class UnauthorizedError extends createSpecificError({
  name: "UnauthorizedError",
  defaultMessage:
    "Vous n'êtes pas autorisé à effectuer cette action ou acceder a cette ressource.",
  defaultStatus: 401,
}) {}

export class ForbiddenError extends createSpecificError({
  name: "ForbiddenError",
  defaultMessage:
    "Vous n'avez pas les permissions suffisantes pour effectuer cette action ou acceder a cette ressource.",
  defaultStatus: 403,
}) {}

export class CsrfError extends createSpecificError({
  name: "CsrfError",
  defaultMessage:
    "Vous n'êtes pas autorisé à effectuer cette action ou acceder a cette ressource. Token CSRF invalide.",
  defaultStatus: 403,
}) {}

export class HoneypotError extends createSpecificError({
  name: "HoneypotError",
  defaultMessage:
    "Vous n'êtes pas autorisé à effectuer cette action ou acceder a cette ressource.",
  defaultStatus: 403,
}) {}

export class NotFoundPageError extends createSpecificError({
  name: "NotFoundPageError",
  defaultMessage: "La page demandée n'existe pas.",
  defaultStatus: 404,
}) {}

export class NotFoundInApiError extends createSpecificError({
  name: "NotFoundInApiError",
  defaultMessage: "Ressource non trouvée dans l'API.",
  defaultStatus: 404,
}) {}

export class ValidationError extends createSpecificError({
  name: "ValidationError",
  defaultMessage: "Erreur dans les données envoyées dans le formulaire.",
  defaultStatus: 422,
}) {}

export class ToastError extends createSpecificError({
  name: "ToastError",
  defaultMessage: "Une erreur est survenue.",
  defaultStatus: 500,
}) {}

export class RecaptchaError extends createSpecificError({
  extend: ToastError,
  name: "RecaptchaError",
  defaultMessage: "Veuillez cocher la case reCAPTCHA.",
  defaultStatus: 500,
}) {}

export class UnreachableApiError extends createSpecificError({
  name: "UnreachableApiError",
  defaultMessage:
    "Service temporairement indisponible. Veuillez réessayer dans quelques instants.",
  defaultStatus: 503,
}) {}

/** Utility function to get the error message from an unknown error */
export function getErrorMessage(error: unknown) {
  const defaultErrorMessage =
    "Une erreur inconnue est survenue, veuillez nous contacter si le problème persiste.";

  if (!error) {
    return defaultErrorMessage;
  }

  if (typeof error === "string") {
    return error;
  }

  if (typeof error === "object") {
    if (
      "hydra:description" in error &&
      typeof error["hydra:description"] === "string"
    ) {
      return error["hydra:description"];
    }
    if ("detail" in error && typeof error.detail === "string") {
      return error.detail;
    }
    if ("message" in error && typeof error.message === "string") {
      return error.message;
    }
    if ("name" in error && typeof error.name === "string") {
      return error.name;
    }
    if ("code" in error && typeof error.code === "string") {
      return error.code;
    }
    if ("data" in error && typeof error.data === "string") {
      return error.data;
    }
  }

  console.error(`Unable to get error message from error: ${error}`);
  return defaultErrorMessage;
}

/** Create a specific error class that extends AppError */
function createSpecificError({
  extend,
  name,
  defaultMessage,
  defaultStatus,
}: {
  extend?: typeof AppError;
  name: string;
  defaultMessage: string;
  defaultStatus: number;
}) {
  const ExtendedError = extend ?? AppError;
  return class CustomError extends ExtendedError {
    constructor(message?: string | null, options?: AppErrorOptions) {
      super(message ?? defaultMessage, {
        status: options?.status ?? defaultStatus,
        fieldErrors: options?.fieldErrors ?? null,
        data: options?.data ?? null,
      });
      this.name = name;
    }
  };
}
